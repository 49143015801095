import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminLogin = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      axios
        .post(
          "https://applications.digitalexpressway.net/api/query/login",
          { user_id: username, password: password },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.is_authenticated) {
            setIsAuthenticated(true);
            setError(null);
            sessionStorage.setItem("isAuthenticated", "true");
          } else {
            setError("Authentication failed");
            sessionStorage.setItem("isAuthenticated", "false");
          }
        })
        .catch((error) => {
          setError("Error: " + error.message);
          sessionStorage.setItem("isAuthenticated", "false");
        });
    } catch (error) {
      setError("this is error: " + error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const acceptedFileTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    const validFiles = files.filter((file) =>
      acceptedFileTypes.includes(file.type)
    );

    if (validFiles.length !== files.length) {
      toast.error("Please select only .pdf, .docx, or .txt files");
    }

    setFilesToUpload(validFiles);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (filesToUpload.length === 0) {
      toast.error("Please select at least one file to upload");
      return;
    }

    const formData = new FormData();
    filesToUpload.forEach((file) => formData.append("pdfs", file));

    const checkAuth = sessionStorage.getItem("isAuthenticated") === "true";
    try {
      await axios.post(
        `https://applications.digitalexpressway.net/api/query/upload?is_authenticated=${checkAuth}`,
        formData,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Files uploaded successfully");
      setFilesToUpload([]);
    } catch (error) {
      toast.error("Failed to upload files");
    }
  };

  const handleDelete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete the file?"
    );
    const checkAuth = sessionStorage.getItem("isAuthenticated") === "true";
    if (confirmed) {
      try {
        await axios.post(
          `https://applications.digitalexpressway.net/api/query/delete?is_authenticated=${checkAuth}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        toast.success("File deleted successfully");
      } catch (error) {
        toast.error("Failed to delete file");
      }
    } else {
      toast.error("Delete operation canceled");
    }
  };

  if (isAuthenticated) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen px-4 bg-gray-100">
        <h1 className="text-4xl font-bold mb-8">Admin Dashboard</h1>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            multiple
            accept=".pdf, .docx, .txt"
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            onClick={handleUpload}
          >
            Upload Document
          </button>
          <button
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
            onClick={handleDelete}
          >
            Delete Document
          </button>
        </div>
        {filesToUpload.length > 0 && (
          <div className="mt-4 w-full max-w-md">
            <h2 className="text-lg font-semibold">Selected Files:</h2>
            <ul className="list-disc list-inside">
              {filesToUpload.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Admin Login
        </h2>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label htmlFor="username" className="sr-only">
                User Email
              </label>
              <input
                id="username"
                name="email"
                type="text"
                autoComplete="email"
                required
                className="appearance-none rounded-lg w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="User Email"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                className="appearance-none rounded-lg w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Login
            </button>
          </div>
        </form>
        {error && <div className="mt-4 text-red-600 text-center">{error}</div>}
      </div>
    </div>
  );
};

export default AdminLogin;
